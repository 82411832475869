import classNames from 'classnames';
import Link from 'components/Link';
import xss from 'utils/xss';
import styles from './TextBlock.module.scss';

function TextBlock({
  content_block: {
    anchor_id: anchorId,
    background_color: backgroundColor,
    content_alignment: contentAlignment,
    content_width: contentWidth,
    cta_text: ctaText,
    cta_url: ctaUrl,
    header_content: headerContent,
    header_type: headerType,
    link_text: linkText,
    link_url: linkUrl,
    text_content: textContent,
    text_color: textColor = 'Navy',
    use_small_heading: useSmallHeading = false,
  },
}) {
  const HeaderTag = headerType || 'h2';

  const bgColor = backgroundColor || '#fff';

  const alignment =
    contentAlignment === 'Center'
      ? `align-items-center justify-content-center text-center`
      : `align-items-left justify-content-left text-left`;

  const linkStyle = ctaUrl
    ? `${styles.link} ${styles.marginLeft}`
    : `${styles.link}`;

  const width =
    contentWidth === 'Standard'
      ? `${styles.standardWidth}`
      : contentWidth === 'Blog'
      ? `${styles.blogWidth}`
      : '';

  const showCtaDiv = ctaUrl || linkUrl;

  const useDarkText = !textColor || textColor === 'Navy';

  return (
    <section
      className={styles.section}
      id={anchorId}
      style={{ background: bgColor }}
    >
      <div
        className={classNames(
          'd-flex flex-column text-primary',
          alignment,
          width,
          styles.content
        )}
      >
        {headerContent && (
          <div
            className={classNames('font-freight fw-900 mb-8', {
              [styles.small]: useSmallHeading,
              [styles.large]: !useSmallHeading,
              [styles.darkText]: useDarkText,
              [styles.lightText]: !useDarkText,
            })}
          >
            <HeaderTag className="lh-1">{headerContent}</HeaderTag>
          </div>
        )}
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: xss(textContent) }}
          className={classNames('font-acumin mb-8', alignment, styles.body, {
            [styles.darkText]: useDarkText,
            [styles.lightText]: !useDarkText,
          })}
        />
        {showCtaDiv && (
          <div className={classNames('d-flex flex-row', alignment)}>
            {ctaUrl && (
              <Link
                href={ctaUrl}
                className={classNames(
                  'font-geo fw-600 bg-secondary text-primary text-uppercase',
                  styles.cta
                )}
              >
                {ctaText}
              </Link>
            )}
            {linkUrl && (
              <Link
                href={linkUrl}
                className={classNames(
                  'font-acumin fw-400 text-primary',
                  linkStyle,
                  {
                    [styles.darkText]: useDarkText,
                    [styles.lightText]: !useDarkText,
                  }
                )}
              >
                {linkText}
              </Link>
            )}
          </div>
        )}
      </div>
    </section>
  );
}

export default TextBlock;
