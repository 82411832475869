import xss from 'xss';

function customXss(html, opts) {
  return xss(html, {
    whiteList: {
      ...xss.whiteList,
      button: ['style'],
      a: [...xss.whiteList.a, 'data-href'],
    },
    onTagAttr(tag, name, value) {
      if (name === 'href' && value.startsWith('sms')) {
        return name + '="' + xss.escapeAttrValue(value) + '"';
      }
    },
    ...opts,
  });
}

export default customXss;
